// Links
//
// Style anchor elements.

$link-color:                              $info;
$link-decoration:                         none;
$link-shade-percentage:                   20% !default;
//$link-hover-color:                        shift-color($link-color, $link-shade-percentage) !default;
$link-hover-color:                        $link-color;
$link-hover-decoration:                   none;

$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y:        .8rem;
$table-cell-padding-x:        .8rem;
$table-cell-padding-y-sm:     .35rem;
$table-cell-padding-x-sm:     .35rem;

$table-cell-vertical-align:   top !default;

$table-color:                 var(--#{$prefix}body-color) !default;
$table-bg:                    transparent !default;
$table-accent-bg:             transparent !default;

$table-th-font-weight:        null !default;

$table-striped-color:         $table-color !default;
$table-striped-bg-factor:     .03;
$table-striped-bg:            rgba($black, $table-striped-bg-factor) !default;

$table-active-color:          $table-color !default;
$table-active-bg-factor:      .1 !default;
$table-active-bg:             rgba($black, $table-active-bg-factor) !default;

$table-hover-color:           $table-color !default;
$table-hover-bg-factor:       .075 !default;
$table-hover-bg:              rgba($black, $table-hover-bg-factor) !default;

$table-border-factor:         .1 !default;
$table-border-width:          $border-width !default;
$table-border-color:          var(--#{$prefix}border-color) !default;

$table-striped-order:         odd !default;
$table-striped-columns-order: even !default;

$table-group-separator-color: currentcolor !default;

$table-caption-color:         $text-muted !default;

$table-bg-scale:              -80% !default;
// scss-docs-end table-variables


//
// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-min-width: 14rem;
$dropdown-padding-y: $spacer * 0.75;

$dropdown-item-wrapper-paddindg-y: 0 !default;
$dropdown-item-wrapper-paddindg-x: $spacer * 0.75 !default;
$dropdown-item-wrapper-min-height: 3rem !default;

$dropdown-item-color: #808191 !default;
$dropdown-item-border-radius: $border-radius !default;
$dropdown-item-active-bg-color: $primary !default;

$dropdown-item-icon-size: 1.5rem !default;
$dropdown-item-icon-space: $spacer !default;

$dropdown-border-radius: $border-radius * 1.5;
$dropdown-border-width: 0;

$dropdown-dark-bg: shade-color($primary, 93);

//
// Cards
//
$card-bg: $white !default;
$dark-card-bg: #242731 !default;

$card-spacer-y: $spacer * 1.5;
$card-spacer-x: $spacer * 1.5;
$card-border-radius: $border-radius * 2;

$card-border-color: $gray-300;
$dark-card-border-color: $gray-700;
$card-border-opacity: 0.5 !default;
$dark-card-border-opacity: 0.5 !default;
$card-border-width: 0;
$card-box-shadow: $box-shadow !default;

$card-header-min-height: 4.5rem !default;
$card-header-padding-y: $card-spacer-y !default;
$card-header-background-color: $card-bg !default;
$dark-card-header-background-color: transparent !default;
$card-header-border-color: $card-border-color !default;
$card-header-border-opacity: 0.5 !default;
$card-header-border-size: 0 !default;
$card-header-color: null !default;

$card-header-icon-size: 2.5rem !default;
$card-header-icon-space: $spacer * 0.5 !default;
$card-header-icon-color: null !default;
$card-header-title-font-weight: 700 !default;

$card-body-padding-y: $card-spacer-y !default;

$card-footer-min-height: 4.5rem !default;
$card-footer-padding-y: $card-spacer-y !default;
$card-footer-background-color: $card-bg !default;
$dark-card-footer-background-color: $dark-card-bg !default;
$card-footer-border-color: $card-border-color !default;
$card-footer-border-opacity: 0.5 !default;
$card-footer-border-size: 0 !default;
$card-footer-color: null !default;

//
// Accordion
$accordion-color: var(--#{$prefix}body-color) !default;
$dark-accordion-color: $dark-body-color !default;
$dark-accordion-bg: $dark-body-bg !default;

$dark-accordion-button-color: $dark-accordion-color !default;
$dark-accordion-button-bg: $dark-accordion-bg !default;

$accordion-border-width: 0;
$accordion-button-font-size: 1.25rem !default;
$accordion-button-font-weight: 700 !default;

$accordion-button-icon-size: 2.5rem !default;
$accordion-button-icon-color: $primary !default;
$accordion-button-icon-space: $spacer * 0.5 !default;

$dark-accordion-button-color: $accordion-color !default;

$dark-accordion-icon-color: $dark-accordion-button-color !default;

$dark-accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark-accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

// Tooltips
