//
// Helpers
// Settings - Bootstrap - Tools - Mixins - Components - Vendors - HELPERS - Utilities
//

/*
 *
 | Contains all CSS ONLY class helpers. Not business classes are allowed here
 *
 */

.text-inherit { text-transform: inherit !important; }
.text-initial { text-transform: initial !important; }
.text-color-inherit { color: inherit !important; }
.text-underline-inherit { text-decoration: inherit !important; }

.font-family-cursive { font-family: cursive !important; }

.text-underline {
  text-decoration: underline !important;

  @at-root #{&}-hover:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}

.center-hor-ver {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.center-ver {
  display: flex !important;
  align-items: center !important;
}

.center-hor {
  display: flex !important;
  justify-content: center !important;
}

.visibility-hidden { visibility: hidden !important; }
.vertical-align-top { vertical-align: top !important; }
.vertical-align-middle { vertical-align: middle !important; }

.z-index-10 {z-index: 10 !important;}
.outline-none {outline: none !important;}

.p-15,
.pl-15,
.px-15 { padding-left: 15px !important; }

.p-15,
.pr-15,
.px-15 { padding-right: 15px !important; }

.p-15,
.pt-15,
.py-15 { padding-top: 15px !important; }

.p-15,
.pb-15,
.py-15 { padding-bottom: 15px !important; }

.text-overflow-clip { text-overflow: clip !important; }
.text-overflow-ellipsis { text-overflow: ellipsis !important; }

.bg-none { background: none !important; }

$font-sizes: inherit, initial, revert, unset, xx-small, x-small, small, smaller, medium, large, larger, x-large, xx-large, xxx-large;
@each $size in $font-sizes {
  .fs-#{$size} { font-size: $size !important; }
}

$font-styles: italic;
@each $size in $font-styles {
  .font-#{$size} { font-style: $size !important; }
}

$white-spaces: inherit, initial, revert, unset, normal, nowrap, pre, pre-line, pre-wrap;
@each $space in $white-spaces {
  .white-space-#{$space} { white-space: $space !important; }
}

$word-breaks: break-all, break-word, keep-all, normal, initial, revert, inherit, unset;
@each $value in $word-breaks {
  .word-break-#{$value} { word-break: $value !important; }
}

$cursors: inherit, initial, revert, unset, auto, default, pointer, none;
@each $cursor in $cursors {
  .cursor-#{$cursor} { cursor: $cursor !important; }
}

$overflow-x: inherit, initial, revert, unset, visible, hidden, scroll, auto;
@each $overflow in $overflow-x {
  .overflow-x-#{$overflow} { overflow-x: $overflow !important; }
}

$overflow-y: inherit, initial, revert, unset, visible, hidden, scroll, auto;
@each $overflow in $overflow-y {
  .overflow-y-#{$overflow} { overflow-y: $overflow !important; }
}


/* ---- BOX-SHADOWS ---- */
.box-shadow-none { box-shadow: none !important; }

$px-sizes: -20px, -10px, 0px, 10px, 20px, 30px, 40px, 50px, 60px, 70px, 80px, 90px, 100px;
@each $size in $px-sizes {
  .height-#{$size} { height: $size !important; }
  .width-#{$size} { width: $size !important; }
  .min-height-#{$size} { min-height: $size !important; }
  .min-width-#{$size} { min-width: $size !important; }
  .max-height-#{$size} { max-height: $size !important; }
  .max-width-#{$size} { max-width: $size !important; }
  .top-#{$size} { top: $size !important; }
  .right-#{$size} { right: $size !important; }
  .bottom-#{$size} { bottom: $size !important; }
  .left-#{$size} { left: $size !important; }
}

$name-sizes: inherit, initial, revert, unset, auto, fit-content, max-content, min-content;
@each $size in $name-sizes {
  .height-#{$size} { height: $size !important; }
  .width-#{$size} { width: $size !important; }
}

$vh-sizes: 25vh, 50vh, 75vh, 100vh;
@each $size in $vh-sizes {
  .h-#{$size} { height: $size !important; }
  .w-#{$size} { width: $size !important; }
}

/* BORDER */
$border-sizes: 1px, 2px, 3px, 4px, 5px, 10px;
@each $size in $border-sizes {
  .border-radius-#{$size} { border-radius: $size !important; }
  .border-width-#{$size} { border-width: $size !important; }
}

$border-radius-sizes: 0, 25, 50, 100;
@each $size in $border-radius-sizes {
  .border-radius-#{$size} { border-radius: percentage($size / 100) !important; }
}


$margin-sizes: -1px, -2px, -3px, -4px, -5px, -10px, 1px, 2px, 3px, 4px, 5px, 10px;
@each $size in $margin-sizes {
  .mt-#{$size} { margin-top: $size !important; }
  .me-#{$size} { margin-right: $size !important; }
  .mb-#{$size} { margin-bottom: $size !important; }
  .ms-#{$size} { margin-left: $size !important; }
}

$all-colors: () !default;
$all-colors: map-merge($colors, $all-colors);
$all-colors: map-merge((
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900
), $all-colors);
@each $color, $value in $all-colors {
  .bg-#{$color} {
    background: $value !important;
  }

  .text-#{$color} {
    color: $value !important;
  }

  .border-#{$color} {
    border-color: $value !important;
  }
}

$all-colors-and-themes: map-merge($theme-colors, $all-colors);
@each $color, $value in $all-colors-and-themes {
  .bg-hover-#{$color}:hover {
    background: $value !important;
  }

  .text-hover-#{$color}:hover {
    color: $value !important;
  }
}
