

// Notifications

$notification-min-width: 20rem;
$notification-max-width: 21.875rem;
$notification-gutter: $grid-gutter-width;
$notification-space: $spacer;
$notification-border-radius: $border-radius-sm;
$notification-box-shadow: $box-shadow;

$notification-timer-height: 0.25rem;
$notification-timer-top: $spacer;

$notification-close-gutter: $spacer * 0.5;
$notification-close-size: 1.4rem;

// stylelint-disable-next-line annotation-no-unknown
$notification-types: (
	'default': $dark,
	'success': $success,
	'info': $info,
	'warning': $warning,
	'danger': $danger,
	'awesome': $primary,
) !default;
