@import "../../../../assets/sass/settings/index";
@import "../../../../assets/sass/mixins/colors";

.circle-check-anim {
  display: inline-block;
  vertical-align: top;
  height: 300px;
  width: 300px;
  opacity: 1;
  overflow: visible;

  @keyframes circle-check-anim-tick {
    0% {
      stroke-dashoffset: 16px;
      opacity: 1
    }

    100% {
      stroke-dashoffset: 31px;
      opacity: 1
    }
  }

  @keyframes circle-check-anim-outline {
    0% {
      stroke-dashoffset: 72px;
      opacity: 1
    }

    100% {
      stroke-dashoffset: 0px;
      opacity: 1
    }
  }

  @keyframes circle-check-anim-fill {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &.circle-check-anim-success {
    .circle-check-anim-outline {
      stroke: $primary-color;
    }
    .circle-check-anim-fill {
      fill: tint-color($primary-color, 75);
    }
    &.dark .circle-check-anim-fill {
      fill: #1f2128;
    }
    .circle-check-anim-tick {
      stroke: $primary-color;
    }
  }

  .circle-check-anim-tick {
    fill: none;
    stroke-width: 1px;
    //stroke: #ffffff;
    stroke-dasharray: 15px, 15px;
    stroke-dashoffset: -14px;
    animation: circle-check-anim-tick 450ms ease 1400ms forwards;
    opacity: 0;
  }

  .circle-check-anim-outline {
    fill: none;
    stroke-width: 1px;
    //stroke: #81c038;
    stroke-dasharray: 72px, 72px;
    stroke-dashoffset: 72px;
    animation: circle-check-anim-outline 300ms ease-in-out 800ms forwards;
    opacity: 0;
  }

  .circle-check-anim-fill {
    //fill: #81c038;
    stroke: none;
    animation: circle-check-anim-fill 300ms ease-out 1100ms forwards;
    opacity: 0;
  }

  // Can't animate stroke-dashoffset on IE 10 and 11, just show svg instead
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .circle-check-anim-tick {
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
      animation: none;
      opacity: 1;
    }

    .circle-check-anim-outline {
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
      animation: none;
      opacity: 1;
    }

    .circle-check-anim-fill {
      animation: none;
      opacity: 1;
    }
  }
}
