@import "../../assets/sass/settings/variables";

.custom-dnd-zone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: $primary;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;

  .thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;

    .thumb-item {
      display: inline-flex;
      border-radius: 2px;
      border: 1px solid rgb(234, 234, 234);
      margin-bottom: 8px;
      margin-right: 8px;
      //width: 100px;
      //height: 100px;
      min-width: 200px;
      min-height: 200px;
      max-width: 300px;
      max-height: 300px;
      padding: 4px;
      box-sizing: border-box;
      position: relative;

      .thumb-wrap {
        display: flex;
        min-width: 0px;
        overflow: hidden;

        .thumb-elt {
          display: block;
          width: auto;
          height: 100%;
        }
      }

      .close-btn {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        border-radius: 0% 0% 0% 100%;
        background: $primary;
        transition: all .4s;
        /*width: 10px;
        height: 10px;
        background: red;*/

        &:hover {
          background: $primary-300;
        }

        i {
          font-size: 20px;
          margin-bottom: 15px;
          margin-left: 15px;
        }
      }

      &.raw-file {
        background: linear-gradient(to bottom, #eee, #ddd);
        padding: 5px;
      }
    }
  }
}

.image-input {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  //width: 100px;
  //height: 100px;
  min-width: 200px;
  min-height: 200px;
  max-width: 300px;
  max-height: 300px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;

  .thumb-wrap {
    display: flex;
    min-width: 0px;
    overflow: hidden;

    .thumb-elt {
      display: block;
      width: auto;
      height: 100%;
      max-width: 300px;
    }
  }

  .close-btn {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border-radius: 0% 0% 0% 100%;
    background: $primary;
    transition: all .4s;
    /*width: 10px;
    height: 10px;
    background: red;*/

    &:hover {
      background: $primary-300;
    }

    i {
      font-size: 20px;
      margin-bottom: 15px;
      margin-left: 15px;
    }
  }
}
