.data-table {
  thead {
    background: linear-gradient(45deg, #a0b952, #4fa46d, #008f88)!important;

    th {
      color: white;
    }
  }

  .datatable-pagination {
    .MuiTablePagination-toolbar {
      padding: 0;
    }
    [class*='MuiTablePagination-select'] {
      margin: 0
    }

    .MuiTablePagination-displayedRows {
      display: none;
    }
  }
}
