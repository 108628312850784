
// stylelint-disable
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge((
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
), $grays);

$primary:                                  #009687; // 500
$primary-50:                               #e0f2f1;
$primary-100:                              #b2dfdb;
$primary-200:                              #80cbc3;
$primary-300:                              #4db6ab;
$primary-400:                              #26a699;
$primary-500:                              #009687;
$primary-600:                              #00897a;
$primary-700:                              #00796a;
$primary-800:                              #00695b;
$primary-900:                              #004d3f;
$primary-dark:                             #00695f;
$primary-light:                            #33ab9f;

$secondary:                                  #BF0A30; // 800
$secondary-50:                               #fdeaef;
$secondary-100:                              #facad4;
$secondary-200:                              #e9949d;
$secondary-300:                              #dd6b78;
$secondary-400:                              #e84559;
$secondary-500:                              #ed2c41;
$secondary-600:                              #de213f;
$secondary-700:                              #cc1639;
$secondary-800:                              #BF0A30;
$secondary-900:                              #b00026;
$secondary-dark:                             #850721;
$secondary-light:                            #cb3b59;

$blue:                                    #1E4498; //Manage Primary Color
$teal:                                    #677080; // Manage Secondary Color
$cyan:                                    #00D0BD; //Manage Info Color
$green:                                   #09d088; //Manage Success Color
$yellow:                                  #FFB70F ; //Manage Warning Color
$red:                                     #FF3739; //Manage Danger Color

$purple:                                  #564fc1;
$orange:                                  #D46B08; // Manage Orange Color
$indigo:                                  #174176;
$pink:                                    #FF2B72;

$blue:                                     $blue;
$blue-light:                               #5d92f4;
$success:                                  $green;
$info:                                     #6c5dd3;
$warning:                                  $yellow;
$danger:                                   $red;
$danger-medium:                            #ef3f40;
$light:                                    $gray-100;
$dark:                                     $gray-900;
$orange:                                   $orange;
$purple:                                   $purple;
$muted-color:                              #909090;
$green-gradient:                           linear-gradient(45deg, #a0b952, #4fa46d, #008f88);

$colors: () !default;
$colors: map-merge((
        "blue":           $blue,
        "indigo":         $indigo,
        "purple":         $purple,
        "pink":           $pink,
        "red":            $red,
        "orange":         $orange,
        "yellow":         $yellow,
        "green":          $green,
        "teal":           $teal,
        "cyan":           $cyan,
        "white":          $white,
        "black":          $black,
        "gray":           $gray-600,
        "green-gradient": $green-gradient,
        "primary":        $primary,
        "primary-50":     $primary-50,
        "primary-100":    $primary-100,
        "primary-200":    $primary-200,
        "primary-300":    $primary-300,
        "primary-400":    $primary-400,
        "primary-500":    $primary-500,
        "primary-600":    $primary-600,
        "primary-700":    $primary-700,
        "primary-800":    $primary-800,
        "primary-900":    $primary-900,
        "primary-bold":   $primary-dark,
        "primary-light":  $primary-light,
        "secondary":        $secondary,
        "secondary-50":     $secondary-50,
        "secondary-100":    $secondary-100,
        "secondary-200":    $secondary-200,
        "secondary-300":    $secondary-300,
        "secondary-400":    $secondary-400,
        "secondary-500":    $secondary-500,
        "secondary-600":    $secondary-600,
        "secondary-700":    $secondary-700,
        "secondary-800":    $secondary-800,
        "secondary-900":    $secondary-900,
        "secondary-bold":    $secondary-dark,
        "secondary-light":    $secondary-light,
), $colors);

$theme-colors: () !default;
$theme-colors: map-merge((
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
), $theme-colors);

// colors aliases
$primary-color: $primary;
$secondary-color: $secondary;

// Prefix for :root CSS variables

$variable-prefix:             bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;

//
// Body
//
// Settings for the `<body>` element.
$body-color: #323232;

$dark-body-color: $light !default;
$dark-body-bg: #1f2128 !default;

//
// Components
//
// Define common padding and border radius sizes and more.

$border-color: $gray-300;
$dark-border-color: $gray-700;

$border-radius: 1rem;
$border-radius-sm: $border-radius * 0.875;
$border-radius-lg: $border-radius * 1.25;

$box-shadow: 0 1.6rem 3rem rgba($black, 0.1);
$box-shadow-sm: 0 0.8rem 3rem rgba($black, 0.075);
$box-shadow-lg: 0 1.6rem 3rem rgba($black, 0.175);

//
// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-shadows: true;
$enable-blur: true;

$font-family-poppins:       "Poppins", "Roboto", "Arial", monospace !default;
$font-family-base:            $font-family-poppins;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              0.9rem; // `13px`
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$btn-contrast-ratio: 1.5 !default;

//
// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-aside: 1035;
$zindex-app-container: $zindex-aside - 100;
$zindex-dropdown: 1071;

//
// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-font-weight: 600;

$btn-box-shadow: unset;
$btn-focus-box-shadow: unset;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$btn-contrast-ratio: 1.5 !default;

