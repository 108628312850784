//
//	General
//

@mixin child-space($space: $spacer) {
	> *:not(:last-child) {
		@include margin-right($space);
	}
}

@mixin child-space-bottom($space: $spacer) {
	> *:not(:last-child) {
		@include margin-bottom($space);
	}
}

// For only Safari
@mixin only-safari() {
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) {
			@content;
		}
	}
}

@mixin rtl($value, $ltr-property, $rtl-property) {
	@at-root html:not([dir='rtl']) & {
		#{$ltr-property}: $value;
	}

	@at-root [dir='rtl'] & {
		#{$rtl-property}: $value;
	}
}

@mixin dark-mode($withOutParent: false) {
	@if $withOutParent {
		@at-root [theme='dark'] {
			@content;
		}
	} @else {
		@at-root [theme='dark'] & {
			@content;
		}
	}
}

//
//	Shadow Mixins
//

@mixin shadow-variants-button() {
	// stylelint-disable declaration-no-important
	&-none:hover {
		box-shadow: none !important;
	}

	&:hover {
		box-shadow: $box-shadow !important;
	}

	&-sm:hover {
		box-shadow: $box-shadow-sm !important;
	}

	&-lg:hover {
		box-shadow: $box-shadow-lg !important;
	}

	&-inset:hover {
		box-shadow: $box-shadow-inset !important;
	}
	// stylelint-enable declaration-no-important
}

@mixin clip-path($value) {
	-webkit-clip-path: $value;
	clip-path: $value;
}
