@use 'sass:math';
@use 'sass:map';

.scroll-margin {
  scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
  top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
  --#{$prefix}border-opacity: 0;
  border-width: 0;
}

.global-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9998;

  & div:first-child {
    height: 6px !important;
  }

  &-overlay {
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    top: 6px;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 9999;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    transition: all 0.4s;
  }
}


/*
 | REACT-TRANSITIONS
 */

.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms;
}

.fade-enter .question-anim {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .question-anim {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .question-anim {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .question-anim {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active .question-anim,
.fade-exit-active .question-anim {
  transition: opacity 500ms, transform 500ms;
}

.custom-transition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.custom-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.custom-transition-exit {
  opacity: 1;
}
.custom-transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/*
 | END OF REACT-TRANSITIONS
 */

main {

  aside {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: map.get($aside, width);
    z-index: map.get($aside, z-index);
    transition: all .2s ease-out;
  }

  > .aside-backdrop {
    z-index: calc(#{map.get($aside, z-index)} - 10);
    position: fixed;
    inset: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-out 0s, visibility 0.3s ease-out 0s;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section.app-container {
    position: relative;
    padding-left: map.get($aside, width);
    z-index: $zindex-app-container;
    transition: all .2s ease-out;

    .main-content-wrapper {
      display: flex;
      flex: auto;
      flex-direction: column;
      min-height: calc(100vh - #{map.get($header, height)});

      .main-content {
        flex: 1;
      }
    }
  }

  &.aside-hidden {
    aside {
      left: calc(-1rem - #{map.get($aside, width)});
    }

    section.app-container {
      padding-left: 0;
    }
  }
}


aside {
  color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px;

  &.with-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 200ms ease 0s;
    height: 100%;

    .aside-overlay {
      position: relative;
      z-index: 0;

      &.no-scroll {
        min-height: 100%;
      }

      &:before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        opacity: .9;

        background: linear-gradient(0deg,#464D69 0%,#464d69 100%);
      }
    }
  }
}


.header {
  align-items: center;
  background-color: white;
  border-bottom: 0 solid #f8f9fa;
  box-shadow: 0 1.6rem 3rem rgb(0 0 0 / 10%);
  color: #808191;
  display: flex;
  height: map.get($header, height);
  padding: 0 0.75rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: map.get($header, z-index);
  @include margin-bottom($grid-gutter-width * 1);
}

.main-content {
  position: relative;

  .subheader {
    --#{$prefix}subheader-bg: #{rgba(
			map.get($subheader, background-color),
			map.get($subheader, opacity)
		)};
    --#{$prefix}subheader-color: #{if(
			map.get($subheader, color),
			map.get($subheader, color),
			color-contrast(map.get($subheader, background-color), map.get($subheader, color-dark))
		)};
    --#{$prefix}subheader-border-bottom-color: #{map.get($subheader, border-color)};

    @include padding(map.get($subheader, padding-y) map.get($subheader, padding-x));
    @include backdrop-filter-blur(map.get($subheader, blur));
    position: sticky;
    z-index: math.round($zindex-sticky - 1);
    top: map.get($header, height);
    display: flex;
    min-height: map.get($subheader, height);
    align-items: center;
    border-bottom: map.get($subheader, border-size) solid
    var(--#{$prefix}subheader-border-bottom-color);
    margin-top: map.get($content, padding-y) * -1;
    margin-bottom: map.get($content, padding-y);
    //background-color: var(--#{$prefix}subheader-bg);
    box-shadow: map.get($header, box-shadow);
    color: var(--#{$prefix}subheader-color);

    //@include margin-left($grid-gutter-width * 0.5);
    //@include margin-right($grid-gutter-width * 0.5);

    @include media-breakpoint-down(map.get($aside, mobile-breakpoint)) {
      min-height: fit-content;

      > * {
        @include padding-top($spacer);
        @include padding-bottom($spacer);
      }
    }

    .subheader-left {
      @include child-space;

      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        @include child-space-bottom;

        flex-direction: column;
        align-items: flex-start;
      }
    }

    .subheader-right {
      @include child-space;

      display: flex;
      align-items: center;
    }


  }

  .subheader-separator {
    height: 2rem;
    border-left: 1px solid map-deep-get($subheader, separator, border-color);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .page-content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    @include padding-top($grid-gutter-width * 1);
    @include padding($grid-gutter-width * 1);
  }
}

footer {
  align-items: center;
  //background-color: hsla(0,0%,100%,.5);
  background-color: white;
  border-top: 1px solid #f8f9fa;
  bottom: 0;
  color: #323232;
  display: flex;
  height: 3rem;
  padding: 0 0.75rem;
}

.bx-x-sm { font-size: 1.35rem !important; }